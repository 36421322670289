import React from 'react';

import Layout from '../containers/Layout/Layout';
import { Container, Heading, Margins, Seo } from '../components';
import { useTranslation } from '../hooks';
import PzuLogo from '../assets/images/pzu-valge.png';
import * as style from './GeneralTermsOfService.module.css';

const GeneralTermsOfService = ({ pageContext: { currentPath } }) => {
  const { t, locale } = useTranslation();

  return (
    <Layout>
      <Seo
        title={t('SEO.GENERAL_TERMS_OF_SERVICE.TITLE')}
        location={currentPath}
        lang={locale}
      />
      <Container>
        <Margins size="2x">
          <Heading level="h2" visualLevel="h5" uppercase>
            {t('GENERAL_TERMS_OF_SERVICE.TITLE')}
          </Heading>
          <div>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>1. ÜLDSÄTTED</strong>
            </h3>
            <p>
              1.1. Üldtingimused sätestavad kliendi (edaspidi nimetatud{' '}
              <strong>Klient</strong>) ja <strong>Serve OÜ</strong> (edaspidi
              nimetatud <strong>Teenusepakkuja</strong>) vahelised õigused ja
              kohustused.
            </p>
            <p>
              1.2. Teenusepakkuja osutab Kliendile iseseisvat füsioteraapia
              teenust (edaspidi nimetatud <strong>Teenus</strong>)
              ravijärjekorra alusel (vt täpsemalt kodulehel{' '}
              <a href="http://www.fysioterapeut.ee">www.fysioterapeut.ee</a>).
            </p>
            <p>
              1.3. Ravijärjekorda registreeritud patsient saab vajaliku
              tervishoiuteenuse minimaalse aja jooksul, lähtudes
              tervishoiuteenuse meditsiinilisest vajadusest ja Tervisekassa
              nõukogu poolt kinnitatud ravijärjekordade maksimumpikkusest.
            </p>
            <p>
              1.4. Teenusepakkuja osutab Teenust Merimetsa Tervisekeskuses
              aadressil Paldiski mnt 68a, Tallinn või Tuleviku 4, Rakvere.
              Koduvisiidil osutatakse Teenust Kliendi osundatud aadressil.
            </p>
            <p>
              1.5. Teraapia tulemuslikkus on individuaalne. Teenusepakkuja ei
              garanteeri ega anna lubadust ega prognoosi ühegi osutatava Teenuse
              efektiivsust või edukust.
            </p>
            <p>
              1.6. Teenusepakkujal on igal ajal õigus käesolevaid Üldtingimusi
              ühepoolselt muuta.
            </p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>
                2. LEPINGU SÕLMIMINE, AJA BRONEERIMINE JA TEENUSE EEST TASUMINE
              </strong>
            </h3>
            <p>
              2.1. Teenuse osutamise leping loetakse Teenusepakkuja ja Kliendi
              vahel sõlmituks hetkest, kui Klient broneerib Teenusepakkuja
              juurde vastuvõtuaja ehk registreeritakse ravijärjekorda.
            </p>
            <p>2.2. Ravijärjekorda registreerimine toimub:</p>
            <ul className={style['generalTermsOfService__list']}>
              <li>
                telefoni 5033622 teel tööpäevadel ajavahemikus kella 9:00-17:00
              </li>
              <li>
                e-post teel aadressil{' '}
                <a href="mailto:info@fysioterapeut.ee">info@fysioterapeut.ee</a>
              </li>
              <li>
                veebilehel{' '}
                <a href="https://www.fysioterapeut.ee/registreerimine">
                  https://www.fysioterapeut.ee/registreerimine
                </a>
                &nbsp;
              </li>
              <li>digiregistratuuris.</li>
            </ul>
            <p>
              2.3. Vastuvõtuaja aja broneerimisega nõustub Klient Teenusepakkuja
              Üldtingimustega ja Teenusepakkuja Teenuste hinnakirjaga{' '}
              <a href="https://fysioterapeut.ee/teenused-ja-hinnad/">
                https://fysioterapeut.ee/teenused-ja-hinnad/
              </a>{' '}
              &nbsp;ning kohustub neid nõuetekohaselt täitma.
            </p>
            <p>
              2.4. Teenusepakkujal on õigus igal ajal ühepoolselt Teenuse
              hinnakirja muuta, tehes vastavad muudatused kättesaadavaks
              Teenusepakkuja kodulehel. Kliendile, kes on teinud broneeringu
              enne hinnakirja muutmist, kuid kasutab Teenust pärast hinnakirjas
              muudatuse tegemist, kehtib broneeringu tegemise hetkel kehtinud
              hinnakiri. Hinnakirja muutmise järgselt broneeringu muutmisel
              rakendub ka muudetud hinnakiri.
            </p>
            <p>
              2.5. Teenuse eest saab tasuda Teenusepakkuja juures kohapeal
              sularahas või Stebby keskkonna piletiga, võimalus on tasuda ka
              ülekandega tingimusel, et raha laekub koheselt Teenusepakkuja
              arveldusarvele. Teenusepakkujal on õigus põhjust avaldamata nõuda
              Teenuse eest ettemaksu tasumist.
            </p>
            <p>
              2.6. Ravijärjekorda registreerimisel kantakse E-Kliinik vahendusel
              ravijärjekorda järgmised andmed:
            </p>
            <ol className={style['generalTermsOfService__list']}>
              <li>
                &nbsp;patsiendi ees- ja perekonnanimi, isikukood (puudumisel
                sünniaeg);
              </li>
              <li>&nbsp;planeeritav vastuvõtu aeg;</li>
              <li>&nbsp;planeeritava tervishoiuteenuse osutamise näidustus;</li>
              <li>&nbsp;patsiendi kontaktandmed (mobiil, e-mail);</li>
              <li>&nbsp;suunava arsti kontaktandmed (suunamise olemasolul);</li>
              <li>&nbsp;patsiendi ravijärjekorda registreerimise kuupäev;</li>
              <li>
                &nbsp;ravijärjekorras muudatuste tegemise (ravijärjekorras ette-
                või tahapoole viimise või ravijärjekorrast väljaarvamise)
                kuupäev ja põhjus;
              </li>
              <li>
                &nbsp;märge, et patsienti on teavitatud ravijärjekorras
                muudatuste tegemisest.
              </li>
            </ol>
            <p>
              2.7. Kliendi registreerimisel ravijärjekorda kliiniku tegevuskohas
              annab Teenusepakkuja Kliendile kirjaliku teatise, mis sisaldab
              Teenuse osutamise aega ja Teenust osutava spetsialisti nime ning
              telefoninumbrit.
            </p>
            <p>
              2.8. Kliendi registreerimisel ravijärjekorda telefoni või e-posti
              teel, teatab Teenuseosutaja Kliendile telefoni või e-posti teel
              Teenuse osutamise aja ja Teenust osutava spetsialisti nime.
            </p>
            <p>
              2.9. Esmakordsel registreerimisel peab Kliendi ravijärjekorda
              registreerima esimesele võimalikule vabale vastuvõtuajale või
              muule ajale, mis on Kliendile sobiv ja korduvale vastuvõtule
              registreerimisel vastavalt spetsialisti soovitusele ja Kliendi
              otsusele, lähtuvalt ravivajadusest.
            </p>
            <p>
              2.10. Ravijärjekorras muudatuste tegemisel peab Kliendile
              muudatustest teatama hiljemalt ühe tööpäeva jooksul arvates
              muudatuste tegemisest ning säilitama varasema teabe koos märkega
              millal on Klienti muudatustest teavitatud.
            </p>
            <p>
              2.11. Teenusepakkuja ei vastuta andmeside ja sideteenuste
              toimimise eest ega sellise teenuse täielikkuse, täpsuse või
              katkestusteta toimimise eest ning osutab Teenuseid, nagu need on
              kättesaadavad. Teenusepakkujal on õigus piirata või peatada
              erinevate broneeringu viiside kasutamist ja vastavalt Teenuse
              osutamise kanalit, näiteks ülekoormuse, tehniliste põhjuste või
              hooldustööde, samuti väärkasutamise või muudel Teenusepakkujast
              mittesõltuvate asjaolude tõttu.
            </p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>3. BRONEERINGU TÜHISTAMINE KLIENDI POOLT</strong>
            </h3>
            <p>
              3.1. Kui Klient ei saa broneeritud ajal Teenust kasutada ja soovib
              broneeringu tühistada ehk Teenuse osutamise lepingu üles öelda,
              siis kohustub Klient broneeringu tühistama esimesel võimalusel,
              kuid hiljemalt 24 tundi enne broneeritud aega.
            </p>
            <p>
              3.2. Klient saab broneeringu tühistada Teenusepakkuja juures
              kohapeal, telefoni teel helistades või saates sms-i numbril +372
              5033622; e-kirja teel info@fysioterapeut.ee.
            </p>
            <p>
              3.3. Kui Klient ütleb broneeritud aja ehk Teenuse osutamise
              lepingu üles vähem kui 24 tundi enne teenuse osutamiseks
              kokkulepitud Teenuse osutamise aega või jätab Teenuse osutamiseks
              kokkulepitud ajal tulemata, on Teenusepakkujal õigus Kliendilt
              nõuda ja Kliendil kohustus tasuda ära jäänud teenuse eest tasu
              täies ulatuses. Juhul kui Teenusepakkujal osutab Kliendile
              Tervisekassa rahastatud teenuseid ning Klient ei muuda või ei
              tühista broneeringut vähemalt 24 tundi enne broneeringus Teenuse
              osutamiseks kokku lepitud aega või ei ilmu broneeritud ajal
              teenuse osutaja juurde, siis on Teenusepakkujal õigus nõuda
              Kliendilt Teenuse eest tasumist summas, mille muidu oleks katnud
              Tervisekassa rahastus.
            </p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>
                4. BRONEERINGU MUUTMINE VÕI TÜHISTAMINE TEENUSEPAKKUJA POOLT
              </strong>
            </h3>
            <p>
              4.1. Teenusepakkujal on õigus muuta või tühistada Kliendi
              broneering eelkõige kui:
            </p>
            <p>
              4.1.1. Teenust ei ole võimalik Kliendile osutada Teenuse
              osutamiseks broneeritud ajal ettenägematu asjaolu tõttu, näiteks
              Teenusepakkuja teenuse osutaja haigestumise või meditsiiniseadme
              rikke tõttu või muudel olulistel põhjustel
            </p>
            <p>
              4.1.2. Klient hilineb broneeritud vastuvõtule enam kui 10 minutit;
            </p>
            <p>
              4.1.3. Teenusepakkujale saab teatavaks Kliendi selline tervislik
              seisund, mis välistab Teenuse pakkumise;
            </p>
            <p>
              4.1.4. Klient ei ole tähtajaks tasunud kokkulepitud ettemaksu;
            </p>
            <p>
              4.1.5. Teenusepakkujale saab teatavaks muu asjaolu, mis takistab
              Teenuse nõuetekohast osutamist.
            </p>
            <p>
              4.2. Teenusepakkuja teavitab esimesel objektiivsel võimalusel
              Klienti broneeringu tühistamisest broneeringus märgitud kontaktil,
              pakkudes võimalusel broneeringuks uut aega.
            </p>
            <p>
              4.3. Teenusepakkujal on õigus Kliendile Teenuse osutamisest
              keelduda või lõpetada Teenuse osutamine, kui:
            </p>
            <p>
              4.3.1. Klient soovib saada Teenust, mille osutamine ei ole
              meditsiiniliselt näidustatud või mis Teenusepakkuja hinnangul ei
              ole asjakohane;
            </p>
            <p>
              4.3.2. Klient soovib saada Teenust, mille osutamine tooks tema
              tervisele kaasa suurema riski kui Teenuse osutamata jätmine;
            </p>
            <p>
              4.3.3. Klient soovib saada Teenust, mille osutamine võib seada
              ohtu Teenusepakkuja või kolmanda isiku tervise;
            </p>
            <p>
              4.3.4. Klient soovib Teenuseid, mille osutamiseks Teenusepakkujal
              puudub kompetents;
            </p>
            <p>
              4.3.5. Klient rikub oma kohustusi, mis tulenevad Üldtingimustest,
              Kliendile esitatud juhenditest ja juhistest või õigusaktidest;
            </p>
            <p>
              4.3.6. Klient ei avalda Teenusepakkujale vajalikku teavet Teenuse
              osutamiseks;
            </p>
            <p>
              4.3.7. Teenusepakkujal ei ole võimalik Kliendiga temale
              arusaadavas keeles suhelda või Kliendil ei ole võimalik kaasata
              tõlki, mistõttu võib Teenus osutuda Kliendile ebasobivaks või
              tervisele ohtlikuks;
            </p>
            <p>
              4.3.8. Klient ei pea kinni Teenusepakkuja sisekorrareeglitest,
              eelkõige kui helisalvestab, filmib või pildistab Teenusepakkujat
              või Teenuse osutamist ilma nõusolekuta;
            </p>
            <p>
              4.3.9. Kliendil esinevad vastuvõtule tulles Teenusepakkuja
              hinnangul alkoholi, narkootikumide või muu joobe tunnused;
            </p>
            <p>
              4.3.10. Klient käitub Teenusepakkuja hinnangul ebaviisakalt,
              provotseerivalt või muul viisil lugupidamatult;
            </p>
            <p>
              4.3.11. Klient rikub Teenuse osutamise eest Teenusepakkujale tasu
              maksmise kohustust;
            </p>
            <p>
              4.3.12.Klient taotleb talle Teenuse osutamist tingimustel, mis on
              vastuolus Üldtingimustega.
            </p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>5. POOLTE ÕIGUSED JA KOHUSTUSED</strong>
            </h3>
            <p>
              5.1. Teenusepakkuja ja Klient teevad parima võimaliku tulemuse
              saavutamiseks koostööd.
            </p>
            <p>
              5.2. Teenusepakkujal on õigus lisaks teistele Üldtingimustes
              toodud õigustele:
            </p>
            <p>
              5.2.1. saada Kliendilt informatsiooni, mis on vajalik broneeritud
              Teenuse osutamiseks, ja edastada Kliendile Teenuse kasutamisega
              seotud informatsiooni;
            </p>
            <p>
              5.2.2. nõuda Teenuse eest õigeaegselt tasumata jätmise korral
              Kliendilt viivist suuruses 0,02% õigeaegselt tasumata summalt iga
              viivitatud päeva eest kuni Teenuse tasu täieliku tasumiseni;
            </p>
            <p>
              5.2.3. tühistada broneering ja öelda üles leping kooskõlas
              käesolevate Üldtingimustega ja õigusnormidega ning keelduda uue
              broneeringu tegemisest.
            </p>
            <p>
              <strong>5.3. Kliendil on õigus:</strong>
            </p>
            <p>
              5.3.1. saada broneeritud ajal nõuetele ja õigusnormidele vastavat
              Teenust;
            </p>
            <p>5.3.2. tühistada broneeritud aeg koosõlas Üldtingimustega;</p>
            <p>
              5.3.3. esitada ettepanekuid ja anda tagasisidet Teenusepakkujale;
            </p>
            <p>
              5.3.4. igal ajal ilma põhjust avaldamata öelda üles Teenuste
              osutamise lepingu, tasudes vaid talle juba osutatud teenuste eest
              ja vähem kui 24 tundi etteteatamisega broneeringu tühistamise
              eest.
            </p>
            <p>
              <strong>5.4. Teenusepakkuja</strong> <strong>kohustub:</strong>
            </p>
            <p>
              5.4.1. osutama Kliendile broneeritud Teenust broneeritud ajal,
              välja arvatud, kui esinevad Teenuse osutamisest keeldumise või
              Teenuse osutamise lõpetamise asjaolud;
            </p>
            <p>
              5.4.2. teavitama Klienti tema tervisega seotud asjaoludest, ravi
              käigust ja tulemustest, pakutava Teenuse olemusest ja otstarbest,
              selle osutamisega kaasnevatest ohtudest ja tagajärgedest ning
              teistest võimalikest ja vajalikest teenustest ning raviarstilt
              info saamise võimalustest nii enne Teenuse osutamist kui ka selle
              ajal;
            </p>
            <p>
              5.4.3. Teenuse osutamise nõuetekohaselt dokumenteerima ning
              vastavaid dokumente säilitama;
            </p>
            <p>
              5.4.4. andma hea tervise ja elukvaliteedi tõstmise eesmärgil
              Kliendile tervisealast teavet.
            </p>
            <p>
              <strong>5.5. Klient kohustub:</strong>
            </p>
            <p>
              5.5.1. edastama Teenusepakkujale tõest informatsiooni ja avaldama
              kõik olulised asjaolud, mis on vajalikud kohaseks Teenuse
              osutamiseks ja mis Teenusepakkuja hinnangul võivad mõjutada
              Teenuse osutamist, sh informatsiooni tarvitatavate ravimite,
              eelsoodumuste, ebatervislike harjumuste jms kohta;
            </p>
            <p>
              5.5.2. teisele isikule Teenusele broneeringu tegemisel andma
              Teenusepakkujale teada, kes on tegelikkuses Teenuse saaja,
              edastama vastavad andmed (sh Teenuse saaja ees- ja perekonnanimi,
              e-posti aadress, telefoninumber ja isikukood);
            </p>
            <p>
              5.5.3. andma teada muudest asjaoludest (nt puue, haigusseisundist
              tulenev erivajadus, keelebarjäär jms), mis võivad Teenuse
              osutamisel omada tähtsust ja osutama Teenusepakkujale kaasabi,
              mida Teenusepakkuja Teenuse osutamiseks vajab;
            </p>
            <p>
              5.5.4. kinni pidama Teenusepakkuja kehtestatud juhenditest,
              instruktsioonidest ja muudest Kliendile teatavaks tehtud nõuetest
              seoses Teenusega;
            </p>
            <p>5.5.5. õigeaegselt saabuma Teenuse osutamise asukohta.</p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>6. TEENUSE OSUTAMISE ERITINGIMUSED</strong>
            </h3>
            <p>
              6.1. Teenuse osutamiseks aja broneerimisega on Klient nõustunud
              Teenusepakkuja Üldtingimustega, võtnud omale kohustuse täita
              Üldtingimusi ja avaldanud nõusolekut Teenuse saamiseks.
            </p>
            <p>
              6.2. Teenusepakkujal on õigus Kliendilt nõuda kirjalikult
              taasesitatavas vormis eraldi nõusolekut Teenuste osutamiseks.
            </p>
            <p>
              6.3. Piiratud teovõimega Kliendi seaduslikul esindajal (näiteks
              alaealise lapse vanemad) on õigus anda Kliendi eest nõusolek
              Teenuse osutamiseks õigusnormides sätestatud korras. Kui piiratud
              teovõimega Kliendi seadusliku esindaja antud nõusolek kahjustab
              Teenusepakkuja hinnangul Kliendi huve, siis Teenusepakkujal on
              õigus Teenust mitte osutada. Kui selline olukord leiab aset
              Teenuse osutamise ajal, on Teenusepakkujal õigus nõuda Teenuse
              eest tasu täies mahus.
            </p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>7. VASTUTUS</strong>
            </h3>
            <p>
              7.1. Teenusepakkuja ei vastuta mistahes kahju eest, mis on
              otseselt või kaudselt tekkinud Kliendi tegevuse või tegevusetuse
              tulemusel, sh Kliendi poolt andmete puudulikul esitamisel või
              esitamata jätmisel.
            </p>
            <p>
              7.2. Teenusepakkuja ei vastuta mistahes Kliendil tekkivate
              negatiivsete tagajärgede eest, mille võimalikkusest on Klienti
              enne Teenuse osutamist teavitatud ning Klient on teavitusest
              hoolimata soovinud Teenust saada.
            </p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>8. ISIKUANDMETE TÖÖTLEMINE</strong>
            </h3>
            <p>
              8.1. Teenusepakkuja töötleb Teenuste osutamise käigus või muul
              viisil kogutud Kliendi isikuandmeid vastavalt isikuandmete kaitse
              üldmäärusele, isikuandmete kaitse seadusele ja teistele kohastele
              õigusaktidele. Teenusepakkuja mitte mingil juhul ei edasta Kliendi
              isikuandmeid ärilistel eesmärkidel kolmandatele isikutele.
            </p>
            <p>
              8.2. Isikuandmete vastutav töötleja on Serve OÜ, kes järgib
              andmete töötlemisel vastavaid õigusakte.
            </p>
            <p>8.3. Teenusepakkuja töötleb järgmisi Kliendi isikuandmeid:</p>
            <p>
              8.3.1. <strong>Kliendi isiku tuvastamine</strong>: Ees- ja
              perekonnanimi, isikukood. <strong>Kasutamine</strong>: Kliendile
              Teenuse broneerimiseks ja Teenuse osutamiseks.
            </p>
            <p>
              8.3.2.<strong>&nbsp;Kontaktandmed</strong>: Telefoninumber,
              e-posti aadress. <strong>Kasutamine</strong>: Kliendile Teenuse
              broneerimiseks ja Kliendiga ühenduse võtmiseks, sh Teenusega
              seotud täiendavate juhiste ja instruktsioonide edastamiseks enne
              ning pärast Teenuse osutamist ja Teenuse osutamise aja ja koha
              kohta meeldetuletuse saatmiseks.
            </p>
            <p>
              8.3.3. <strong>Terviseandmed</strong>: Andmed Kliendi tervisliku
              seisundi kohta, sh andmed, millist arsti Klient on külastanud või
              soovib külastada; andmed Kliendile osutatud Teenustest ning nende
              Teenuste raames Kliendilt kogutud andmed; andmed Kliendi poolt
              tarvitatavate ravimite kohta; samuti Teenuse osutamiseks tehtud
              röntgenpildid ja/või muud kliinilised pildid. Terviseandmete
              koosseis ja ulatus, mida Teenusepakkuja konkreetsel juhul töötleb,
              sõltub Kliendi poolt valitud Teenusest. Teenuse ja selle käigu
              dokumenteerimiseks. <strong>Kasutamine</strong>: Teenuse osutamise
              kavandamiseks, sh haiguse või vigastuse ennetamiseks,
              diagnoosimiseks ja ravimiseks eesmärgiga leevendada vaevusi, hoida
              ära Kliendi tervise seisundi halvenemist või haiguse ägenemist
              ning taastada tervist, samuti vajadusel Kliendi perearstile
              edastamiseks.
            </p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>9. ANDMETE SÄILITAMINE</strong>
            </h3>
            <p>
              9.1. Teenusepakkuja ei säilita isikuandmeid kauem, kui see on
              lähtuvalt isikuandmete töötlemise eesmärgist või kehtiva õiguse
              alusel vajalik;
            </p>
            <p>
              9.2. Tervishoiuteenuste korraldamise seaduse ja sotsiaalministri
              määruse &bdquo;Tervishoiuteenuse osutamise dokumenteerimise
              tingimused ja kord&ldquo; alusel säilitab Teenusepakkuja
              ambulatoorse ning statsionaarse tervishoiuteenuse osutamist
              tõendavaid andmeid reeglina 30 aastat Kliendile osutatud
              Tervishoiuteenuse andmete kinnitamisest ja Teenuse osutaja
              infosüsteemi logisid säilitatakse 5 aastat;
            </p>
            <p>
              9.3.Kliendi rahulolu hindamiseks kogutud tagasisidet säilitatakse
              5 aastat alates tagasiside saamisest;
            </p>
            <p>
              9.4. Raamatupidamisseaduse alusel säilitatakse raamatupidamislikke
              dokumente 7 aastat.
            </p>
            <p>
              9.5. Kliendil on alati õigus nõuda oma andmete kustutamist
              õiguskorras ettenähtud moel, viisil, mahus ja ulatuses.
            </p>
            <h3 className={style['generalTermsOfService__heading']}>
              <strong>10. KOOSTÖÖ TERVISEKASSAGA</strong>
            </h3>
            <p>
              10.1. Lepingumahu raames Tervisekassa poolt rahastatavate teenuste
              puhul tasub Teenuse eest Tervisekassa. Tervisekassa poolt
              rahastatavate Teenuste puhul ei võeta visiiditasu. Klienti saab
              Tervisekassa rahastusega Teenusele suunata perearst ja ka eriarst.
            </p>
            <p>
              10.2. Ambulatoorsesse ravijärjekorda registreerimiseks on vajalik
              digitaalne saatekiri perearsti poolt.
            </p>
            <p>
              10.3. Juhul, kui Tervisekassa ei rahasta Teenust või keeldub
              hiljem Teenuse eest tasumisest, kannab Klient Teenusega seotud
              kulud täies mahus. Tervisekassa ei rahasta Teenust eelkõige
              järgnevatel juhtudel:
            </p>
            <ul className={style['generalTermsOfService__list']}>
              <li>&nbsp;Kliendil puudub kehtiv ravikindlustus</li>
              <li>&nbsp;Klient ei soovi avaldada oma andmeid</li>
              <li>
                &nbsp;Klient ei soovi, et tema andmed edastatakse Tervisekassale
              </li>
              <li>&nbsp;Teenuse osutamiseks puudub meditsiiniline näidustus</li>
              <li>&nbsp;Tervisekassa lepingumahu täitumisel</li>
            </ul>
            <p>
              10.4. õigeaegselt tühistamata Tervisekassa rahastatava Teenuse
              korral on Teenusepakkujal õigus nõuda Kliendilt järgnevate
              Teenuste eest tasu hinnakirja alusel ja ettemaksuna.
            </p>
          </div>

          <Heading
            level="h2"
            visualLevel="h5"
            id="vastutuskindlustus"
            uppercase
          >
            Vastutuskindlustuse info
          </Heading>
          <Margins>
            <p>
              <strong>Kindlustusandja:</strong> PZU Kindlustus (AB „Lietuvos
              draudimas“ Eesti filiaal)
            </p>
            <p>
              <strong>Kindlustuskaitse periood:</strong> 1.11.2024-30.10.2025
              (kindlustusperioodi välja palume täita kindlustusvõtjal vastavalt
              lepingule)
            </p>
            <div>
              <p>
                <strong>Kindlustussumma:</strong>
              </p>
              <ul className={style['generalTermsOfService__list']}>
                <li>3 000 000 € kindlustusperioodi kohta</li>
                <li>300 000 € kindlustusjuhtumi kohta</li>
                <li>100 000 € õigustatud isiku kohta</li>
              </ul>
            </div>
            <div>
              <p>
                <strong>Mittevaralise kahju kindlustussumma:</strong>
              </p>
              <ul className={style['generalTermsOfService__list']}>
                <li>100 000 € kindlustusjuhtumi kohta</li>
                <li>30 000 € õigustatud isiku kohta</li>
              </ul>
            </div>
            <div>
              <p>
                <strong>Kahjunõude esitamine:</strong> tervishoiuteenuse enda
                kontakt või{' '}
                <a
                  href="https://www.pzu.ee/iseteenindus/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PZU Kindlustuse Iseteenindus
                </a>
              </p>
              <p>
                Kahju esitamiseks palun täitke{' '}
                <a
                  href="https://www.pzu.ee/iseteenindus/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PZU Kindlustuse Iseteeninduses
                </a>{' '}
                olev vorm ning kindlustusandja võtab teiega ühendust.
              </p>
            </div>
            <a
              href="https://www.pzu.ee/iseteenindus/"
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <img
                src={PzuLogo}
                alt="PZU Iseteenindus"
                loading="lazy"
                height="203"
                width="658"
                className={style['generalTermsOfService__pzuImage']}
              />
            </a>
          </Margins>
        </Margins>
      </Container>
    </Layout>
  );
};

export default GeneralTermsOfService;
